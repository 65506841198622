<template>
	<div>
		<DateRangeV2
            :start.sync="start_date"
            :end.sync="end_date"
            @submit="init_component"
			:ShowTodayButton="true"
			:dateSelect="true"
			:periodToShow="[ 'personalise','jour', 'demain', 'J+2', 'J+3', 'mois_dernier','semaine_derniere']"
        />

        <div class="row">
        	<div class="col-12">
        		<div class="alert alert-secondary" role="alert">
	        		<span v-html="$t('mouvement.suggestion_saillie_mare')"></span>
	        	</div>
	        </div>
        </div>

		<CustomTable
			ref="table"
			id_table="suggestion_mouvement_saillie_mare"
			:busy.sync="table_busy"
			primaryKey="uniq_id"
			:hide_if_empty="true"
			:hrefsRoutes="config_table_hrefs"
			:items="suggestions"
		/>
    </div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
	import Mouvements from "@/mixins/Mouvements.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterDeplacement from '@/mixins/shutters-manager/Deplacement.js'
	import { EventBus } from 'EventBus'

	export default {
		name: 'SuggestionListeStallion',
		mixins: [Shutter, Mouvements, Navigation, ShutterDeplacement],
		data () {
			return {
				table_busy: false,
				suggestions: [],
				start_date: new Date(new Date().setDate(new Date().getDate() - 15)),
				end_date: new Date(new Date().setDate(new Date().getDate() + 15)),
				config_table_hrefs: {
                    'stallion_name': {
                        routeUniqueName: 'stallionFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'stallion_id'
                        }
                    },
                    'mare_name': {
                        routeUniqueName: 'mareFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'mare_id'
                        }
                    },
                    'tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers_id'
                        }
                    }
				},
				events_tab: {
					'TableAction::goToAddMouvementFromSuggestion': this.addMouvements
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.suggestions = await this.getSuggestionMouvementSaillie('mare', this.start_date.toDateInputValue(), this.end_date.toDateInputValue())
				this.table_busy = false
			},

			suggestionFormSubmit() {
				this.$refs.table.unselectAll()
				this.init_component()
			},

			async addMouvements(params) {
				let stallions = params.map(p => ({
					horse_id: p.mare_id,
					tiers_id: p.tiers_id,
					date: p.actes_date,
				}))
				let res = await this.addMouvementFromSuggestion(stallions)
				EventBus.$emit("TableAction::stopSpin")
				if(res == null) {
					this.failureToast("toast.info_save_failed")
					return false
				}
				this.successToast('toast.suggestion_mvt_created')
				this.$refs.table.unselectAll()
				this.init_component()
			}
		},

		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>